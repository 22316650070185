import React from 'react';
import { Header } from "../Header/Header";
import { Main } from "../Main/Main";
import { Join } from "../Join/Join";
import { DeepMine } from "../DeepMine/DeepMine";

import './App.scss';
import {Footer} from "../Footer/Footer";

const App = () => {
    return (
        <div className="app">
            <img className="app__background" src="/assets/main-bg.png" />
            <Header />
            <Main invitedBy="rms.me" />
            <DeepMine />
            <Join />
            <Footer />
        </div>
    );
}

export default App;
