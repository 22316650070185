import React from 'react';
import { ReactComponent as BlueprintIcon } from '../../icons/blueprint.svg';
import { ReactComponent as DiscordIcon } from '../../icons/discord.svg';
import { ReactComponent as MediumIcon } from '../../icons/medium.svg';
import { ReactComponent as TgIcon } from '../../icons/tg.svg';
import { ReactComponent as TwitterIcon } from '../../icons/twitter.svg';
import logo from '../../icons/logo.png';

import './Header.scss';

export const Header = () => {
    return (
        <div className="header">
            <div className="header__section">
                <a href="#" target="_blank" rel="noreferrer">
                    <div className="header__icon-content">
                        <BlueprintIcon />
                        <div>Blueprint</div>
                    </div>
                </a>
            </div>
            <div className="header__section">
                <a href="#" target="_blank" rel="noreferrer">
                    <img src={logo} alt="DeepMine" />
                </a>
            </div>
            <div className="header__section">
                <a href="#" target="_blank" rel="noreferrer"><DiscordIcon /></a>
                <a href="#" target="_blank" rel="noreferrer"><TgIcon /></a>
                <a href="#" target="_blank" rel="noreferrer"><TwitterIcon /></a>
                <a href="#" target="_blank" rel="noreferrer"><MediumIcon /></a>
            </div>
        </div>
    )
}