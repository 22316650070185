import React from 'react';
import { WaxButton } from "../WaxButton/WaxButton";

import './Main.scss';

export const Main = ({ invitedBy }) => {
    return (
        <div className="main">
            <div className="main__title">
                Join to deepmine game and&nbsp;play&nbsp;with your friends
            </div>
            <div className="main__description">
                <div>Join now and get Recruit sticker for free</div>
            </div>
            <div className="main__invited-by">
                * You were invited by <span>{invitedBy}</span>
            </div>
            <WaxButton text="JOIN WITH WAX WALLET" className="main__button" />
            <a href="#" target="_blank" rel="noreferrer">
                <div className="main__what-is">
                    DON’T KNOW WHAT IS WAX?
                </div>
            </a>
        </div>
    )
}