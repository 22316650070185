import React from 'react';
import logo from '../../icons/logo.png';

import './Footer.scss';

export const Footer = () => (
    <div className="footer">
        <a href="#" target="_blank" rel="noreferrer">
            <img src={logo} alt="DeepMine" />
        </a>
    </div>
);
