import React from 'react';
import { WaxButton } from "../WaxButton/WaxButton";

import './Join.scss';

export const Join = () => {
    return (
        <div className="join">
            <div className="join__content">
                <div className="join__title">
                    join now<br/>
                    and get recruit sticker<br/>
                    for free
                </div>
                <WaxButton className="join__button" text="JOIN WITH WAX WALLET" />
            </div>
            <img className="join__image" src="assets/recruit.png" />
        </div>
    )
}