import React from 'react';

import './DeepMine.scss';

export const DeepMine = () => (
    <div className="deepmine">
        <div className="deepmine__title">
            What is DeepMine?
        </div>
        <div className="deepmine__description">
            <img className="deepmine__description__bg" src="/assets/what-is-deepmine.png" />
            <span>
            Imagine the future so distant that all the stars have collapsed. At human civilization's demise, we are exhausted struggling for the expiring energy on the last inhabited planet in the galaxy called Eleazar. While humanity is dying without energy, salvation lies in constructing the portal into a parallel world which requires a lot of energy and effort from the entire community.
            </span>
        </div>
    </div>
)